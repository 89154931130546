import PropTypes from "prop-types"
import React, { createContext } from "react"

const SiteContext = createContext({
  selectedSign: "",
  setSelectedSign: () => {},
  currentStep: 1,
  setCurrentStep: () => {},
  mainAudio: null,
  setMainAudio: () => {},
  backgroundAudio: null,
  setBackgroundAudio: () => {},
  audioEnded: false,
  setAudioEnded: () => {},
  currentAudioStage: null,
  setCurrentAudioStage: () => {},
  currentAudioTime: 0,
  setCurrentAudioTime: () => {},
  firstName: "",
  setFirstName: () => {},
  lastName: "",
  setLastName: () => {},
  emailAddress: "",
  setEmailAddress: () => {},
  noSleep: null,
  setNoSleep: () => {},
  progressBarPercent: 0,
  setProgressBarPercent: () => {},
})

function SiteProvider({ children }) {
  const [selectedSign, setSelectedSign] = React.useState("")
  const [currentStep, setCurrentStep] = React.useState(1)
  const [mainAudio, setMainAudio] = React.useState(null)
  const [backgroundAudio, setBackgroundAudio] = React.useState(null)
  const [audioEnded, setAudioEnded] = React.useState(false)
  const [currentAudioStage, setCurrentAudioStage] = React.useState(null)
  const [currentAudioTime, setCurrentAudioTime] = React.useState(0)
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [emailAddress, setEmailAddress] = React.useState("")
  const [noSleep, setNoSleep] = React.useState("")
  const [progressBarPercent, setProgressBarPercent] = React.useState(0)

  return (
    <SiteContext.Provider
      value={{
        selectedSign,
        setSelectedSign,
        currentStep,
        setCurrentStep,
        mainAudio,
        setMainAudio,
        backgroundAudio,
        setBackgroundAudio,
        audioEnded,
        setAudioEnded,
        currentAudioStage,
        setCurrentAudioStage,
        currentAudioTime,
        setCurrentAudioTime,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        emailAddress,
        setEmailAddress,
        noSleep,
        setNoSleep,
        progressBarPercent,
        setProgressBarPercent
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

SiteProvider.propTypes = {
  children: PropTypes.node,
}

export { SiteContext, SiteProvider }
